import { PaymentMethodViewModel } from './backend/models';
import { ICoordinates } from './types';

export const APPSTORE_URL = 'https://apps.apple.com/app/hoopla/id1564114367';
export const PLAYSTORE_URL =
  'https://play.google.com/store/apps/details?id=com.trustsourcing.hoopla';
export const INSTAGRAM_URL = 'https://www.instagram.com/experiencehoopla/';
export const FACEBOOK_URL = 'https://www.facebook.com/experiencehoopla';
export const TIKTOK_URL = 'https://www.tiktok.com/@experiencehoopla';
export const YOUTUBE_URL =
  'https://www.youtube.com/channel/UCLO5fmiqtLiFJsMYqI8z09g';
export const LINKEDIN_URL =
  'https://www.linkedin.com/company/experience-hoopla/';

export const VIDEOS_PER_PAGE = 24;
export const GIGS_PER_PAGE = 24;
export const COMMENTS_PER_PAGE = 24;

export const DAYS_OF_THE_WEEK = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
};

// Deafult coordinates for Los Angeles
export const DEFAULT_COORDS: ICoordinates = {
  lat: 34.052235,
  lng: -118.243683
};

//Messages
export const METHOD_PAYMENT_ALREADY_ADDED =
  'Cannot add this payment method: Credit Card already exists in this account.';
export const FIELD_REQUIRED = 'This field is required.';
export const AUTH_CREATED_SUCCESS_MSG = 'Your account has been created!';
export const AUTH_SUCCESS_MSG = "You're now signed in!";
export const AUTH_UNKNOWN_REASON_MSG = 'You need to continue to authenticate.';
export const ACCEPT_TOS_MSG =
  'You must accept our Terms of Service to create an account';
export const PRIVATE_EVENT_MSG =
  'This %EXP_TYPE% requires an invitation from Experience Creator to view';
export const USER_OUTSIDE_SERVICE_AREA_MSG =
  "The Creator doesn't have availability on this service area";

export const TBA_MESSAGE = 'TBA';

export const APPLE_PAY_METHOD: PaymentMethodViewModel = {
  brand: 'applepay',
  last4: null,
  id: 'applepay',
  isDefault: false,
  checks: null
};

export const GOOGLE_PAY_METHOD: PaymentMethodViewModel = {
  brand: 'googlepay',
  last4: null,
  id: 'googlepay',
  isDefault: false,
  checks: null
};

export const CREDIT_DEBIT_METHOD: PaymentMethodViewModel = {
  brand: 'card',
  last4: null,
  id: 'card',
  isDefault: true,
  checks: null
};

export const GOOGLE_CAL_DISCOVERY_DOC =
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
export const GOOGLE_CAL_SCOPES =
  'https://www.googleapis.com/auth/calendar.events';

export const MY_LOCATION: string = 'Current Location';
export const HOOPLA_LOCALDATA = '__hoopla_localdata_payload';

//TODO: Remove this const after the event
export const SUPER_USERS = [
  'mrhsu',
  'mhernandez27',
  'jordan',
  'chiefhoopla',
  'edwin_munguia',
  'edwinmunguia_',
  'melnikq',
  'checkin1',
  'checkin2'
];

export const GRAMMY_ADMINS = [
  'hoopladmn@gmail.com',
  'fnf26@hoopla.com',
  'nicholas9@gmail.com',
  'deron@hoopla.com',
  'marcela.hernandez@hoopla.com',
  'joshua@aaminc.com',
  'ekkim1017@gmail.com',
  'marcela.hernandez+100@hoopla.com',
  'edwinj.munguia90@gmail.com',
  'puma@hoopla.com'
];

export const GRAMMY_USERS = [
  'marcela.hernandez+aaminc@hoopla.com',
  'marcela.hernandez+amazon@hoopla.com',
  'marcela.hernandez+ascap@hoopla.com',
  'marcela.hernandez+atlanticrecords@hoopla.com',
  'marcela.hernandez+bmi@hoopla.com',
  'marcela.hernandez+concord@hoopla.com',
  'marcela.hernandez+disneymusic@hoopla.com',
  'marcela.hernandez+globalmusicrights@hoopla.com',
  'marcela.hernandez+serbanghenea@hoopla.com',
  'marcela.hernandez+hipgnosissongs@hoopla.com',
  'marcela.hernandez+influencemedia@hoopla.com',
  'marcela.hernandez+kobaltmusic@hoopla.com',
  'marcela.hernandez+mnrk@hoopla.com',
  'marcela.hernandez+muso@hoopla.com',
  'marcela.hernandez+prsformusic@hoopla.com',
  'marcela.hernandez+rcarecords@hoopla.com',
  'marcela.hernandez+sonymusicpub@hoopla.com',
  'marcela.hernandez+sonypictures@hoopla.com',
  'marcela.hernandez+soundexchange@hoopla.com',
  'marcela.hernandez+warnerrecords@hoopla.com',
  'marcela.hernandez+warnerchappell@hoopla.com',
  'marcela.hernandez+youtube@hoopla.com',
  'marcela.hernandez+various@hoopla.com'
];

export const GRAMMY_CHECKIN_USERS = [
  'fnf1@hoopla.com',
  'fnf2@hoopla.com',
  'fnf3@hoopla.com',
  'fnf4@hoopla.com',
  'fnf5@hoopla.com',
  'fnf6@hoopla.com',
  'fnf7@hoopla.com'
];

export const DATE_FORMAT = 'ddd, MMM D - hh:mm A';

export const SUPPORT_EMAIL = 'support@hoopla.com';

import { CategoryViewModel } from '../../core/backend/models';
import { RoutesEnum } from '../../core/enums';
import { SelectOption } from '../../core/types';
import CategoryBadge from '../CategoyBadge/CategoryBadge';
import { ReactComponent as HooplaLogo } from '../../images/hooplaicon.svg';
import Select from '../Select/Select';
import { getMediaFile } from '../../core/api';
import { useNavigate } from 'react-router-dom';

interface IProps {
  activeCategory?: string;
  className?: string;
  items: CategoryViewModel[];
}

const CategoryMenu = ({
  activeCategory = 'all',
  className = '',
  items
}: IProps) => {
  const navigate = useNavigate();
  const options: SelectOption[] = [
    {
      id: 'all',
      name: 'All Categories',
      content: (
        <CategoryBadge
          key={`category-0`}
          compact
          data-value={0}
          image={<></>}
          title='All Categories'
          isSelected={activeCategory === 'all'}
        />
      )
    },
    ...items.map<SelectOption>((category, idx) => ({
      id: category.slug,
      name: category.name,
      content: (
        <CategoryBadge
          key={`category-${category.id}`}
          compact
          data-value={idx + 1}
          image={getMediaFile(category?.image)}
          title={category?.name}
          isSelected={activeCategory === category?.slug}
        />
      )
    }))
  ];
  return (
    <div className={`CategoryMenu ${className}`}>
      <Select
        className='CategoryMenu__select'
        options={options}
        value={activeCategory}
        onChange={(slug) => {
          navigate(slug === 'all' ? RoutesEnum.Root : `/category/${slug}`);
        }}
      />
    </div>
  );
};

export default CategoryMenu;

import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  completeRegistration,
  signInWithApple,
  signInWithFacebook,
  signInWithGoogle
} from '../../core/api';
import { UserViewModel } from '../../core/backend/models';
import {
  ACCEPT_TOS_MSG,
  AUTH_CREATED_SUCCESS_MSG,
  AUTH_SUCCESS_MSG
} from '../../core/consts';
import { TrackingEvents } from '../../core/enums';
import {
  generateFullName,
  generateUsename,
  isAndroid
} from '../../core/helpers';
import appToast from '../../core/toast';
import { AppleCredentialResponse } from '../../core/types';
import { DEFAULT_ERROR_MESSAGE } from '../../core/validators';
import AppleAuthButton from '../AppleAuthButton/AppleAuthButton';
import FacebookAuthButton, {
  FacebookCredentialResponse
} from '../FacebookAuthButton/FacebookAuthButton';
import GoogleAuthButton, {
  CredentialResponse
} from '../GoogleAuthButton/GoogleAuthButton';
import Loader from '../Loader/Loader';
import { useAuthModal } from '../Modal/AuthModal/AuthModal';
import './SocialAuthentication.scss';
import {
  AppleAuthRequestModel,
  FacebookLoginRequest
} from '../../core/backend/interfaces';
import EmailAuthButton from '../EmailAuthButton/EmailAuthButton';
import GoogleTagManager from '../../core/services/GoogleTagManager';
import TikTokPixel from '../../core/services/TikTokPixel';
import MetaPixel from '../../core/services/MetaPixel';
import MobileAuthButton from '../MobileAuthButton/MobileAuthButton';

interface IProps {
  label?: string;
  type?: 'signin_with' | 'signup_with';
  shape?: 'standard' | 'icon';
  forceToAcceptTOS?: boolean;
  hasAcceptedTOS?: boolean;
  showLabel?: boolean;
  displayEmailButton?: boolean;
  onEmailAuth?: () => void;
  onSuccess: () => Promise<UserViewModel>;
  onError: (errorMessage?: string) => void;
}

const SocialAuthentication = ({
  label = 'Sign up with:',
  type = 'signin_with',
  shape = 'standard',
  forceToAcceptTOS = true,
  hasAcceptedTOS = false,
  showLabel = false,
  displayEmailButton = true,
  onSuccess,
  onEmailAuth,
  onError
}: IProps) => {
  const { showAuthModal, closeAuthModal } = useAuthModal();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const navigate = useNavigate();

  const authenticateUserWithGoogle = async (
    crendentials: CredentialResponse
  ) => {
    try {
      setIsAuthenticating(true);

      await signInWithGoogle(crendentials.credential);

      const user = await onSuccess();

      if (!user.registrationCompleted) {
        showAuthModal('complete_profile');
        appToast.showSuccess(AUTH_CREATED_SUCCESS_MSG);

        trackSocialSignUpEvent(user);
      } else {
        appToast.showSuccess(AUTH_SUCCESS_MSG);
        closeAuthModal();
      }
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      onError(error);
    } finally {
      setIsAuthenticating(false);
    }
  };

  const authenticateUserWithApple = async (
    credentials: AppleCredentialResponse
  ) => {
    let userName: string = null;

    try {
      setIsAuthenticating(true);

      let fullName = !credentials?.user?.name?.firstName
        ? ''
        : `${credentials?.user?.name?.firstName} ` +
          (credentials?.user?.name?.lastName ?? '');

      const appleAuthPayload: AppleAuthRequestModel = {
        token: credentials.authorization.id_token,
        user: {
          email: credentials?.user?.email,
          name: {
            firstName: credentials?.user?.name?.firstName,
            lastName: credentials?.user?.name?.lastName,
            fullName
          }
        }
      };

      await signInWithApple(appleAuthPayload);
      const user = await onSuccess();

      if (!user.registrationCompleted) {
        if (!fullName?.trim()?.length) fullName = generateFullName();

        userName = generateUsename(fullName);

        showAuthModal('complete_profile', { fullName, userName });
        appToast.showSuccess(AUTH_CREATED_SUCCESS_MSG);

        trackSocialSignUpEvent(user);
      } else {
        appToast.showSuccess(AUTH_SUCCESS_MSG);
      }

      navigate(`/u/${user.userName}`);
      closeAuthModal();
    } catch (e: any) {
      const errorMessage: string =
        e.response?.data?.description || DEFAULT_ERROR_MESSAGE;

      //generated username exists
      //Send the user to change username to void losing unique apple login data
      if (errorMessage.startsWith('Username is taken')) {
        showAuthModal('username', { userName });
        return;
      }

      onError(errorMessage);
    } finally {
      setIsAuthenticating(false);
    }
  };

  const authenticateUserWithFacebook = async (
    credentials: FacebookCredentialResponse
  ) => {
    try {
      setIsAuthenticating(true);

      const facebookAuthPayload: FacebookLoginRequest = {
        email: credentials.email,
        name: credentials.name
      };

      await signInWithFacebook(facebookAuthPayload);

      const user = await onSuccess();

      if (!user.registrationCompleted) {
        showAuthModal('complete_profile');
        appToast.showSuccess(AUTH_CREATED_SUCCESS_MSG);

        trackSocialSignUpEvent(user);
      } else {
        closeAuthModal();
        appToast.showSuccess(AUTH_SUCCESS_MSG);
      }
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      onError(error);
    } finally {
      setIsAuthenticating(false);
    }
  };

  const handleMobileAuth = () => {
    showAuthModal('phone_number');
  };

  const handleValidateTOSAcceptance = (e: React.SyntheticEvent) => {
    if (forceToAcceptTOS && !hasAcceptedTOS) {
      onError(ACCEPT_TOS_MSG);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const trackSocialSignUpEvent = (user: UserViewModel) => {
    GoogleTagManager.dataLayer({
      event: TrackingEvents.SignUp
    });

    MetaPixel.track('CompleteRegistration', {
      content_name: user.fullName,
      content_type: 'product'
    });

    TikTokPixel.track('CompleteRegistration', {
      content_id: user?.id.toString(),
      content_name: user?.fullName
    });
  };

  return (
    <div className='SocialAuthentication'>
      {isAuthenticating && <Loader />}

      <div
        className={classNames('SocialAuthentication__wrapper', {
          'SocialAuthentication__wrapper--iconButtons': shape === 'icon',
          'SocialAuthentication__wrapper--withLabel': showLabel
        })}
      >
        {showLabel && label && (
          <div className='SocialAuthentication__label'>{label}</div>
        )}
        <GoogleAuthButton
          type={type}
          shape={shape}
          onClick={handleValidateTOSAcceptance}
          onSuccess={authenticateUserWithGoogle}
          onError={onError}
        />

        {!isAndroid() && (
          <AppleAuthButton
            type={type === 'signin_with' ? 'sign-in' : 'sign-up'}
            shape={shape}
            onClick={handleValidateTOSAcceptance}
            onSuccess={authenticateUserWithApple}
            onError={onError}
          />
        )}

        <FacebookAuthButton
          type={type}
          shape={shape}
          canContinueBeforeAuthenticate={() => hasAcceptedTOS}
          onSuccess={authenticateUserWithFacebook}
          onError={onError}
        />

        <MobileAuthButton
          type={type}
          shape={shape}
          onClick={handleMobileAuth}
        />

        {displayEmailButton && (
          <EmailAuthButton type={type} shape={shape} onClick={onEmailAuth} />
        )}
      </div>
    </div>
  );
};

export default SocialAuthentication;

import { makeAutoObservable } from 'mobx';
import { getMediaFile } from '../api';
import {
  BookingViewModel,
  GigTicketViewModel,
  GigViewModel
} from '../backend/models';
import { convertDateToLocalUsingTimezone, convertToCurrency } from '../helpers';
import {
  Address,
  FormattedTicketStats,
  ICoordinates,
  IVideoModel,
  MediaFile,
  Thumbnails
} from '../types';
import VideoModel from './VideoModel';
import { GetEventAvailableTicketStatsOutput } from '../backend/models/get-event-available-ticket-stats-0utput';
import { GigVisibility, HostingType } from '../backend/enums';

class ExperienceModel {
  _experience: GigViewModel = null;
  _video: IVideoModel = null;
  _booking: BookingViewModel = null;
  _thumbnails: Thumbnails = undefined;
  _mediaFiles: MediaFile[] = [];
  _ticketStats: FormattedTicketStats;

  constructor(experience: GigViewModel) {
    makeAutoObservable(this);

    this._experience = experience;
    this._video = experience.gigVideo
      ? new VideoModel(experience.gigVideo)
      : null;

    this._processFiles();
  }

  _processFiles() {
    const _mediaFiles = this._experience.gigMainMediaFiles;

    if (!_mediaFiles?.length) return;

    const _sortedMediaFiles = _mediaFiles.sort((a, b) =>
      a.awsUrl.endsWith('.mp4') ? -1 : a.order - b.order
    );

    const _file = _sortedMediaFiles[0];

    const _thumbs = _file?.thumbnails;

    const staticFiles: string[] = _thumbs?.mobile?.static.slice();
    const videoPreview = _thumbs?.mobile?.animated
      ?.slice()
      .find((file) => file.endsWith('.mp4'));

    const webFiles: string[] = _thumbs?.web.slice();

    this._thumbnails = {
      video: videoPreview
        ? getMediaFile(videoPreview)
        : _thumbs?.mobile?.animated?.length > 0
        ? getMediaFile(_thumbs?.mobile?.animated[0])
        : getMediaFile(_file.awsUrl),
      animated:
        webFiles && webFiles[0]
          ? getMediaFile(webFiles[0])
          : getMediaFile(_file.awsUrl),
      static:
        staticFiles && staticFiles[1]
          ? getMediaFile(staticFiles[1])
          : staticFiles && staticFiles[0]
          ? getMediaFile(staticFiles[0])
          : getMediaFile(_file.awsUrl)
    };

    this._mediaFiles = _sortedMediaFiles.map((file) => ({
      id: file.id,
      url: getMediaFile(file.awsUrl),
      isVideo: file.awsUrl.endsWith('mp4')
    }));
  }

  set booking(booking: BookingViewModel) {
    this._booking = booking;
  }

  get id() {
    return this._experience.id;
  }

  get isDraft() {
    return this._experience.visibility === GigVisibility.Draft;
  }

  get provider() {
    return this._experience.provider;
  }

  //Princing Model
  get isHourly() {
    return this._experience.pricingModel === 0;
  }

  get isFixedPrice() {
    return this._experience.pricingModel === 1;
  }

  get isOneTimeEvent() {
    return this._experience.oneEventTime;
  }

  //Sold out
  get isSoldOut() {
    return this._experience.isSoldOut;
  }

  // Closed
  get isEventClosed() {
    if (!this.isOneTimeEvent) return false;

    const eventEndTimeMillis =
      new Date(this.eventStartDate).getTime() + this.eventDurationInMillis;

    return Date.now() >= eventEndTimeMillis;
  }

  //Still bookable
  get isStillBookable() {
    return this.isOneTimeEvent && !this.isEventClosed && !this.isSoldOut;
  }

  //Is Private
  get isPrivate() {
    return this._experience.isPrivateExperience;
  }

  //Hosting Type
  get isVirtual() {
    return this._experience.hostingType === HostingType.Virtual;
  }

  get isInPerson() {
    return this._experience.hostingType === HostingType.InPerson;
  }

  get isToBeAnnounced() {
    return this._experience.hostingType === HostingType.ToBeAnnounced;
  }

  // Event Dates
  get eventStartDate() {
    return this._experience.oneEventTimeTimestampFromSeconds * 1000;
  }

  get eventEndDate() {
    return this._experience.oneEventTimeTimestampToSeconds * 1000;
  }

  get eventTimezoneOffsetInMillis() {
    return this._experience.timeZoneOffsetInSeconds
      ? this._experience.timeZoneOffsetInSeconds * 1000
      : 0;
  }

  get dateTimezone() {
    return this._experience.timeZoneAbbreviation ?? '';
  }

  get minHours() {
    return this._experience.minHours;
  }

  get maxHours() {
    return this._experience.maxHours;
  }

  get eventDurationInHours() {
    return this._experience.oneEventTimeDuration / 3600;
  }

  get eventDurationInMillis() {
    if (this.isHourly) return 0;

    return this.isOneTimeEvent
      ? this._experience.oneEventTimeDuration * 1000
      : this.minHours * 3600 * 1000;
  }

  get experienceDurationInHours() {
    return !this.isHourly ? this._experience.minHours : 0;
  }

  //price

  get price() {
    return this._experience.price;
  }

  get formattedPrice() {
    return this.isFree ? 'Free' : convertToCurrency(this.price);
  }

  get priceLabel() {
    if (this.isFree) return 'Free';
    if (!this.isOneTimeEvent)
      return `${this.formattedPrice}${this.isHourly ? '/hr' : ''}`;

    if (this.customTickets.length > 1)
      return `Starting at ${
        this.ticketsStartingPrice === 0
          ? 'Free'
          : convertToCurrency(this.ticketsStartingPrice)
      }`;
    else
      return `Ticket Price: ${
        this.ticketsStartingPrice === 0
          ? 'Free'
          : convertToCurrency(this.ticketsStartingPrice)
      }`;
  }

  get isFree() {
    return (
      (this._experience.price === 0 && !this.hasCustomTickets) ||
      (this.hasCustomTickets &&
        this._experience.tickets.every((ticket) => ticket.price === 0))
    );
  }

  get discount() {
    return this._experience.priceBaseDiscount > this.price
      ? this._experience.priceBaseDiscount
      : 0;
  }

  get formattedDiscount() {
    return convertToCurrency(this.discount);
  }

  get discountPercentage() {
    return this._experience.discount;
  }

  //Event Location Type
  get isCreatorLocation() {
    return this._experience.locationType === 2;
  }

  get isCustomerLocation() {
    return this._experience.locationType === 1;
  }

  get isFlexibleLocation() {
    return this._experience.locationType === 3;
  }

  get IsAreaFlexible() {
    return this._experience.isServiceAreaFlexible;
  }

  //Address
  get location(): Address {
    if (this.isVirtual)
      return {
        locationName: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null
      };

    return {
      locationName: this._experience.locationName,
      address: this._experience.address ?? this._experience.location,
      address2: this._experience.address2,
      city: this._experience.city,
      state: this._experience.state,
      zip: this._experience.zip
    };
  }

  get address() {
    return `${this.formattedAddress1}, ${this.formattedCityState} ${
      this.location.zip ?? ''
    }`;
  }

  get formattedCreatorAddress() {
    return `${this.formattedAddress1}, ${this.formattedCityState} ${
      this.location.zip ?? ''
    }`;
  }

  get bookingAddress() {
    return this.booking
      ? `${[
          this.booking.location,
          this.booking.address2,
          this.booking.city,
          this.booking.state
        ]
          .filter(Boolean)
          .join(', ')} ${this.booking.zip}`
      : this.address;
  }

  get formattedAddress() {
    return Object.values(this.location).filter(Boolean).join(', ');
  }

  get formattedAddress1() {
    const { address, address2 } = this.location;
    return [address, address2].filter(Boolean).join(', ');
  }

  get formattedAddress2() {
    const { city, state, zip } = this.location;
    return [city, state, zip].filter(Boolean).join(', ');
  }

  get formattedCityState() {
    const { city, state } = this.location;
    return [city, state].filter(Boolean).join(', ');
  }

  get coords(): ICoordinates {
    return {
      lat: this._experience.latitude,
      lng: this._experience.longitude
    };
  }

  get hasCoords() {
    return !!(this._experience.latitude && this._experience.longitude);
  }

  get serviceRadius() {
    return this._experience.serviceRadius;
  }

  //Reviews
  get reviews() {
    return this._experience.reviews ?? [];
  }

  //Descriptions

  get name() {
    return this._experience.name;
  }

  get description() {
    return this._experience.description;
  }

  get shortDescription() {
    return this._experience.shortDescription;
  }

  get whatsIncluded() {
    return this._experience.includedInfo;
  }

  get whatToBring() {
    return this._experience.toBringInfo;
  }

  //Availability
  get creatorAvailability() {
    return this._experience.userAvailability;
  }

  //Creator
  get creator() {
    return this._experience.provider;
  }

  //Media
  get video() {
    return this._video;
  }

  get videoViews() {
    return this._experience.viewedTimes ?? 0;
  }

  set videoViews(views: number) {
    this._experience.viewedTimes = views;
  }

  get thumbnails() {
    return this._thumbnails;
  }

  get mediaFiles() {
    return this._mediaFiles;
  }

  set mediaFiles(mediaFiles: Array<MediaFile>) {
    this._mediaFiles = mediaFiles;
  }

  //Attendees
  get attendees() {
    return this._experience.attendees;
  }

  get bookingCount() {
    return this._experience.bookedTickets;
  }

  get showWhoHasBook() {
    return this._experience.additionalData?.showWhoHasBook ?? false;
  }

  get attendingCount() {
    return this._experience.totalAttending;
  }

  //Booking
  get booking() {
    return this._booking;
  }

  //Category
  get category() {
    return this._experience.category;
  }

  //Likes
  get likes(): number {
    return this._experience.likes ?? 0;
  }

  set likes(count: number) {
    this._experience.likes = count;
  }

  get isRecurrent(): boolean {
    return this._experience.isRecurrentEvent;
  }

  //Tickets
  get hasCustomTickets(): boolean {
    return this._experience.tickets?.length > 0;
  }

  get customTickets(): Array<GigTicketViewModel> {
    return this._experience.tickets ?? [];
  }

  set customTickets(tickets: Array<GigTicketViewModel>) {
    this._experience.tickets = tickets;
  }

  get ticketsStartingPrice() {
    let sortedTickets = [...this._experience.tickets].sort(
      (a, b) => a.price - b.price
    );

    if (!this.isRecurrent) {
      const eventStartDateInMillis = this.eventStartDate * 1000;
      const timezoneOffsetInSeconds = this.eventTimezoneOffsetInMillis / 1000;

      sortedTickets = sortedTickets.filter((ticket) => {
        let ticketEnabledUntil = 0;

        if (ticket.enabledUntil > 0) {
          ticketEnabledUntil = ticket.enabledUntil;
        } else if (ticket.enabledUntilDaysBeforeEventStarts > 0) {
          const ticketEndTime =
            ticket.enabledUntilDaysBeforeEventStarts * 24 * 60 * 60 * 1000;
          ticketEnabledUntil = (eventStartDateInMillis - ticketEndTime) / 1000;
        } else {
          ticketEnabledUntil =
            (eventStartDateInMillis + this.eventDurationInMillis) / 1000;
        }

        const enabledUntil = convertDateToLocalUsingTimezone(
          ticketEnabledUntil,
          timezoneOffsetInSeconds
        );

        return enabledUntil.getTime() > Date.now();
      });
    }

    return sortedTickets[0]?.price ?? this.price;
  }

  //Ticket Stats
  ticketStats(ticketStats: Array<GetEventAvailableTicketStatsOutput>) {
    this._ticketStats = ticketStats.reduce<FormattedTicketStats>(
      (acc, ticketStats) => ({ ...acc, [ticketStats.ticketId]: ticketStats }),
      {}
    );
  }

  get getTicketStats() {
    return this._ticketStats;
  }

  //Additional data
  get additionalData() {
    return this._experience.additionalData;
  }

  get hashtags() {
    return this._experience?.gigHashtags ?? [];
  }
}

export default ExperienceModel;

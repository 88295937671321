import { useEffect } from 'react';
import Button from '../Button/Button';
import { ReactComponent as FacebookIcon } from '../../icons/facebook_white_icon.svg';
import './FacebookAuthButton.scss';
import classNames from 'classnames';

interface Props {
  type?: 'signin_with' | 'signup_with';
  shape?: 'standard' | 'icon';
  canContinueBeforeAuthenticate?: () => boolean;
  onSuccess: (response: FacebookCredentialResponse) => void;
  onError: (status: FacebookResponseStatus) => void;
}

interface FacebookAuthResponse {
  accessToken?: string;
  expiresIn?: string;
  reauthorize_required_in?: string;
  signedRequest?: string;
  userID?: string;
}

type FacebookResponseStatus = 'connected' | 'not_authorized' | 'unknown';

interface FacebookStatusResponse {
  status: FacebookResponseStatus;
  authResponse: FacebookAuthResponse;
}

export interface FacebookCredentialResponse extends FacebookAuthResponse {
  id?: string;
  name?: string;
  email?: string;
}

const FacebookAuthButton = ({
  type = 'signin_with',
  shape = 'standard',
  onSuccess,
  onError,
  canContinueBeforeAuthenticate
}: Props) => {
  useEffect(() => {
    const _window = window as any;

    (function (d, id) {
      var js,
        fjs = d.getElementsByTagName('script')[0];
      if (d.getElementById(id)) return;
      js = d.createElement('script');
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'facebook-jssdk');

    _window.fbAsyncInit = () => {
      _window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: false,
        xfbml: true,
        version: 'v15.0'
      });
    };
  }, []);

  const handleCheckLoginState = (response: FacebookStatusResponse) => {
    if (response.authResponse) {
      getUserInfo(response.authResponse);
    } else {
      onError(response.status);
    }
  };

  const getUserInfo = (credentials: FacebookAuthResponse) => {
    (window as any).FB.api(
      '/me',
      { fields: 'name, email' },
      (user: FacebookCredentialResponse) =>
        handleParseAndReturnUserInfo(user, credentials)
    );
  };

  const handleParseAndReturnUserInfo = (
    userInfo: FacebookCredentialResponse,
    credentials: FacebookAuthResponse
  ) => {
    const userData = Object.assign(userInfo, credentials);
    onSuccess(userData);
  };

  const handleSignIn = () => {
    if (canContinueBeforeAuthenticate && !canContinueBeforeAuthenticate()) {
      return;
    }

    (window as any).FB.getLoginStatus((response: FacebookStatusResponse) => {
      if (response.status === 'connected') {
        handleCheckLoginState(response);
      } else {
        (window as any).FB.login(handleCheckLoginState, {
          scope: 'public_profile,email',
          return_scopes: true,
          auth_type: ''
        });
      }
    });
  };

  return (
    <Button
      className={classNames('FacebookAuthButton', {
        'FacebookAuthButton--icon': shape === 'icon'
      })}
      onClick={handleSignIn}
    >
      <div className='FacebookAuthButton__wrapper'>
        <FacebookIcon className='FacebookAuthButton__icon' />
        {shape === 'standard' && (
          <div className='FacebookAuthButton__label'>
            {type === 'signin_with' ? 'Sign in' : 'Sign up'} with Facebook
          </div>
        )}
      </div>
    </Button>
  );
};

export default FacebookAuthButton;

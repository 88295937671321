import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MediaFile, PlayerState, Thumbnails } from '../../core/types';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import './MediaPlayer.scss';
import { AddOneViewToVideo } from '../../core/api';
import { ReactComponent as CarouselIcon } from '../../icons/carousel.svg';
import MediaPager from '../MediaPager/MediaPager';

interface IProps {
  files: MediaFile[];
  thumbnail?: Thumbnails;
  embed?: React.ReactNode;
  autoPlayVideos?: boolean;
  forceToStopMedia?: boolean;
  addVideoView?: boolean;
  onChangeMediaFile: (newFile: MediaFile) => void;
  afterViewIsAdded: () => void;
}

const settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  arrows: false
};

const IMAGE_DURATION = 3000;

const MediaPlayer = ({
  files,
  thumbnail,
  embed,
  autoPlayVideos,
  forceToStopMedia,
  addVideoView = true,
  onChangeMediaFile,
  afterViewIsAdded
}: IProps) => {
  const slider = useRef<Slider>(null);
  const [innerFiles, setInnerFiles] = useState<MediaFile[]>([]);
  const [videoState, setVideoState] = useState<PlayerState>(
    autoPlayVideos ? 'playing' : 'idle'
  );
  const [canAutoPlay, setCanAutoPlay] = useState(true);
  const isPressingImageRef = useRef(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [playedPercentage, setPlayedPercentage] = useState<number>(0);
  const viewTimerRef = useRef<ReturnType<typeof setTimeout>>(null);
  const imageDurationTimerRef = useRef<ReturnType<typeof setInterval>>(null);
  const currentMediaDurationRef = useRef<number>(0);
  const selectedMediaIdRef = useRef<number>(null);
  const alreadyViewedRef = useRef<boolean>(null);

  useEffect(() => {
    setCurrentIndex(0);
    selectedMediaIdRef.current = null;
    setInnerFiles(files);

    return () => {
      setInnerFiles([]);
      clearViewTimer();
      clearImageDurationTimer();
      isPressingImageRef.current = false;
    };
  }, [files]);

  useEffect(() => {
    setVideoState(autoPlayVideos ? 'playing' : 'idle');
  }, [autoPlayVideos]);

  useEffect(() => {
    if (innerFiles[currentIndex]?.isVideo && videoState === 'playing')
      setVideoState(forceToStopMedia ? 'paused' : 'playing');
    else isPressingImageRef.current = forceToStopMedia;
  }, [innerFiles, currentIndex, forceToStopMedia]);

  useEffect(() => {
    if (!innerFiles.length || !slider.current) return;

    goAndPlayMediaAtIndex(currentIndex);

    return () => {
      setCurrentIndex(0);
      selectedMediaIdRef.current = null;
      alreadyViewedRef.current = false;
      clearViewTimer();
    };
  }, [innerFiles]);

  const canAutoSlideContent = () => innerFiles.every((file) => !file.isVideo);

  const goAndPlayMediaAtIndex = (index: number) => {
    const currentFile = innerFiles[index];

    if (!currentFile.isVideo && innerFiles.length > 1) {
      createImageDurationTimer();
    }

    selectedMediaIdRef.current = currentFile?.id;
    setVideoState(currentFile.isVideo && autoPlayVideos ? 'playing' : 'idle');
    onChangeMediaFile(currentFile);

    /**
     * Allow trigger the view timer multiple times if this has been added
     * and we are accesing a video
     */
    if (alreadyViewedRef.current && !currentFile.isVideo) return;

    addVideoView &&
      triggerViewTimer(currentFile.id, currentFile.isVideo ? 2000 : 1);
  };

  const handleVideoPlayerStateChange = (
    newState: PlayerState,
    prevState: PlayerState
  ) => {
    if (prevState === 'finished' && newState === 'playing') {
      triggerViewTimer(selectedMediaIdRef.current, 2000);
    }

    if (newState === 'finished' && innerFiles.length > 1) {
      slider.current.slickNext();
    }

    setVideoState(newState);
  };

  const handleBeforeChangeMedia = (currentIndex: number, nextIndex: number) => {
    if (nextIndex < 0) return;

    /**
     * We avoid to add a view when video is playing and user
     * changes media before triggering the timeout
     */
    if (videoState === 'playing' && viewTimerRef?.current) {
      clearViewTimer();
    }

    //Clear existing image timer
    clearImageDurationTimer();

    setCurrentIndex(nextIndex);
    goAndPlayMediaAtIndex(nextIndex);
  };

  const handleOnUserSwipe = () => {
    setCanAutoPlay(false);
  };

  const triggerViewTimer = (mediaId: number, duration: number) => {
    viewTimerRef.current = setTimeout(() => {
      AddOneViewToVideo(mediaId).then(() => {
        afterViewIsAdded();
        alreadyViewedRef.current = true;
      });
    }, duration);
  };

  const handleSetMediaDuration = (duration: number): any => {
    currentMediaDurationRef.current = duration * 1000;
  };

  const handleMediaProgress = (playedMilliseconds: number) => {
    setPlayedPercentage(
      calculatePercentageOf(playedMilliseconds, currentMediaDurationRef.current)
    );
  };

  const createImageDurationTimer = () => {
    let currentLap = 0;

    imageDurationTimerRef.current = setInterval(() => {
      if (isPressingImageRef.current) return;

      currentLap += 4;

      if (currentLap >= IMAGE_DURATION) {
        if (innerFiles.length > 1 && slider.current) slider.current.slickNext();
        return clearImageDurationTimer();
      }

      const progress = calculatePercentageOf(currentLap, IMAGE_DURATION);
      setPlayedPercentage(progress);
    }, 4);
  };

  const clearViewTimer = () => {
    clearTimeout(viewTimerRef.current);
    viewTimerRef.current = null;
  };

  const clearImageDurationTimer = () => {
    clearInterval(imageDurationTimerRef.current);
    imageDurationTimerRef.current = null;
  };

  const calculatePercentageOf = (current: number, total: number) => {
    return Math.floor((100 * current) / total);
  };

  const handleMouseDown = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    if (innerFiles[currentIndex].isVideo) setVideoState('paused');
    else isPressingImageRef.current = true;
  };

  const handleMouseOut = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    if (innerFiles[currentIndex].isVideo) setVideoState('playing');
    else isPressingImageRef.current = false;
  };

  return (
    <div className='MediaPlayer'>
      {innerFiles?.length > 1 && (
        <>
          <MediaPager
            className='MediaPlayer__pageIndicator'
            selectedIndex={currentIndex}
            pages={innerFiles.length}
            onPageClick={(idx) => {
              slider.current.slickGoTo(idx);
              // setCanAutoPlay(false);
            }}
            currentPageProgress={playedPercentage}
          />

          <div className='MediaPlayer__pageInfo'>
            <div className='MediaPlayer__pageInfoLabel'>
              {`${currentIndex + 1}/${innerFiles.length}`}
            </div>
            <CarouselIcon />
          </div>
        </>
      )}
      <div
        className='MediaPlayer__wrapper'
        // onMouseDown={handleMouseDown}
        // onMouseUp={handleMouseOut}
        // onMouseLeave={handleMouseOut}
        // onTouchStart={handleMouseDown}
        // onTouchEnd={handleMouseOut}
      >
        <Slider
          ref={slider}
          lazyLoad='progressive'
          beforeChange={handleBeforeChangeMedia}
          onSwipe={handleOnUserSwipe}
          // speed={IMAGE_DURATION}
          // autoplay={canAutoPlay}
          // autoplaySpeed={IMAGE_DURATION}
          {...settings}
        >
          {innerFiles.map((file) => {
            return file.isVideo ? (
              <VideoPlayer
                key={uuidv4()}
                url={file.url}
                background={thumbnail?.static}
                playerState={videoState}
                thumbnail={thumbnail?.static ?? thumbnail?.animated}
                onPlayerStateChange={handleVideoPlayerStateChange}
                onDuration={handleSetMediaDuration}
                onProgress={handleMediaProgress}
              />
            ) : (
              <div key={uuidv4()} className='MediaPlayer__imageWrapper'>
                <div className='MediaPlayer__imageContainer'>
                  <img src={file.url} alt='' />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      {embed && (
        <div className='MediaPlayer__embed'>
          <div className='MediaPlayer__embedContainer'>{embed}</div>
        </div>
      )}
    </div>
  );
};

export default MediaPlayer;

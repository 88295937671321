import React from 'react';
import BookingsStore from '../stores/BookingsStore';
import CategoryStore from '../stores/CategoryStore';
import CommentStore from '../stores/CommentStore';
import CreatorStore from '../stores/CreatorStore';
import FollowUsersStore from '../stores/FollowUsersStore';
import GigStore from '../stores/GigStore';
import HashtagStore from '../stores/HashtagStore';
import LikesStore from '../stores/LikesStore';
import StripeStore from '../stores/StripeStore';
import UserStore from '../stores/UserStore';
import VideoStore from '../stores/VideoStore';
import LocationStore from '../stores/LocationStore';
import AppDataManager from '../stores/AppDataManager';
import BookingStore from '../stores/BookingStore';
import SingleEventStore from '../stores/SingleEventStore';

const storesContext = React.createContext({
  userStore: new UserStore(),
  stripeStore: new StripeStore(),
  gigStore: new GigStore(),
  singleEventStore: new SingleEventStore(),
  videoStore: new VideoStore(),
  creatorStore: new CreatorStore(),
  categoryStore: new CategoryStore(),
  commentStore: new CommentStore(),
  hashtagStore: new HashtagStore(),
  likedMedia: new LikesStore(),
  bookingsStore: new BookingsStore(),
  bookingStore: new BookingStore(),
  followUsers: new FollowUsersStore(),
  locationStore: new LocationStore(),
  appDataManager: new AppDataManager()
});

export const useStores = () => React.useContext(storesContext);
